<template>
    <div class="single-header" :class="{tenancy: is_tenancy}">
        <secondary-header title="GoBills" backRoute="default">
            <template v-slot:menu>
                <div class="header-content container font-weight-bold px-0">
                    <div class="text-right mb-1 pt-2 col-md-10 mx-auto text-truncate">
                        {{ lang('Credit Balance') }}: {{ money(wallet_details.wallet_balance) }}
                        <a href="#" @click.prevent="closeApp('gopay/reload/main')" class="text-info">
                            <font-awesome-icon icon="plus-circle" size="lg"></font-awesome-icon>
                        </a >
                    </div>
                    <div class="details col-md-10 mx-auto">
                        <div class="logo" :style="'background: url('+ bill.branch_logo +');'"></div>
                        <div class="location">
                            <div class="text-truncate">
                                {{ bill.customer_name }}
                            </div>
                            <div class="font-weight-bold text-truncate">{{ bill.location }}</div>
                            <div class="text-truncate">{{ bill.branch_name }}</div>
                        </div>
                    </div>
                    <div class="col-md-10 mx-auto">
                        <div v-if="is_tenancy" class="text-truncate">{{ bill.deposit }}</div>
                        
                        <span class="float-right">
                            <b class="mr-2">{{ lang('Due') }}</b>
                            {{ money(Math.abs(bill.total_due)) }}
                            <span v-if="bill.total_due > 0" class="text-danger font-weight-bold">+</span>
                            <span v-if="bill.total_due < 0" class="text-success">-</span>
                        </span>

                        <div v-if="is_tenancy && bill.merchant_customer_date" class="text-truncate">
                            <a :href="bill.merchant_customer_file_ta" target="_blank" rel="noopener noreferrer" class="text-primary">
                                {{ lang('Expiry Date:') }} {{ dateUi(bill.merchant_customer_date, 'DD MMM YYYY') }}
                            </a>
                        </div>
                        <div v-else style="color: #ffffff00;">{{ lang('Amount Due') }}</div>
                    </div>

                    <div class="px-0 col-md-10 mx-auto branch-tab">
                        <ul class="nav nav-justified" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="pills-home-tab" data-toggle="pill" data-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" @click="page = 'home'">
                                    {{ lang('Home') }}
                                </a>
                            </li>
                            <li v-if="bill.is_have_gobills_feedback" class="nav-item" role="presentation">
                                <a class="nav-link" id="pills-feedback-tab" data-toggle="pill" data-target="#pills-feedback" type="button" role="tab" aria-controls="pills-feedback" aria-selected="false" @click="page = 'feedback'">
                                    {{ lang('Feedback') }} <div v-if="total_unread_reply_count > 0" class="badge badge-pill badge-danger ml-1" style="position: absolute;">{{ total_unread_reply_count < 100 ? total_unread_reply_count : '99+' }}</div>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="pills-transaction-tab" data-toggle="pill" data-target="#pills-transaction" type="button" role="tab" aria-controls="pills-transaction" aria-selected="false" @click="page = 'transaction'">
                                    {{ lang('Transaction') }}
                                </a>
                            </li>
                        </ul>
                    </div>
                    
                </div>
            </template>
        </secondary-header>

        <main>
            <div class="container select-bills">
                <div class="row justify-content-center">
                    <div class="col-md-10 mb-5 tab-content px-0" id="pills-tabContent">

                        <div class="content px-3 tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div class="owl-carousel owl-theme">
                                <div v-for="(banner, i) in home_banners" class="item" :key="i">
                                    <img :src="banner" style="width: calc(100vw - 30px); max-width: 570px; margin: auto;" />
                                </div>
                            </div>
                        </div>
                        <div v-if="bill.is_have_gobills_feedback" class="content tab-pane fade py-3 feedback-list" id="pills-feedback" role="tabpanel" aria-labelledby="pills-feedback-tab">
                            <div v-for="feedback in feedbacks" :key="'feedback-'+ feedback.id" class="pb-2">
                                <div class="header d-flex collapsed" data-toggle="collapse" :data-target="'#collapse-' + feedback.id">
                                    <div class="font-weight-bold my-auto subject">
                                        <div>{{ feedback.created_at }} <div v-if="feedback.unread_reply_count > 0" class="badge badge-pill badge-danger ml-1" style="position: absolute;">{{ feedback.unread_reply_count < 100 ? feedback.unread_reply_count : '99+' }}</div> </div>
                                        <div>{{ feedback.subject }}</div>
                                    </div>
                                    <div :class="feedback.status + ' mr-auto my-auto'">{{ feedback.status_ui }}</div>
                                    <div class="colapse-icon">
                                        <div class="icon"><font-awesome-icon icon="caret-down" size="lg"></font-awesome-icon></div>
                                    </div>
                                </div>
                                <div class="collapse" :id="'collapse-' + feedback.id">
                                    <div class="reply-content ME">
                                        <div class="text">{{ feedback.content }}</div>
                                        <div class="d-flex">
                                            <div class="ml-auto pt-2"></div>
                                            <font-awesome-icon v-for="(attachment, i) in feedback.attachments" icon="paperclip" size="lg" class="text-info ml-2 mb-1" :key="i" @click="viewAttachment(attachment.url, attachment.mime)"></font-awesome-icon>
                                        </div>
                                    </div>
                                    <div v-for="(reply, i) in feedback.reply" :key="'reply-'+ reply.id" :class="'reply-content '+ reply.from" @click="reply.is_unread ? markAsRead(feedback, reply) : ''">
                                        <div class="small">{{ reply.created_at }} <div v-if="reply.is_unread" class="badge badge-pill badge-danger ml-1 text-danger" style="position: absolute;">.</div></div>
                                        <div class="text">{{ reply.content }}</div>
                                        <div class="d-flex">
                                            <div class="ml-auto pt-2"></div>
                                            <font-awesome-icon v-for="(attachment, i) in reply.attachments" icon="paperclip" size="lg" class="text-info ml-2 mb-1" :key="i" @click="viewAttachment(attachment.url, attachment.mime)"></font-awesome-icon>
                                            <font-awesome-icon v-if="feedback.status != 'CLOSED' && i+1 == feedback.reply.length && reply.from == 'THEM'" icon="reply" size="lg" class="ml-2 mb-1" style="color: #2e99ff;" @click="replyFeedback(feedback, reply.id)"></font-awesome-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content px-3 mb-5 tab-pane fade" id="pills-transaction" role="tabpanel" aria-labelledby="pills-transaction-tab">
                            <div class="row pt-2 pb-1 px-0 px-2">
                                <div class="col-6 px-1">
                                    <span class="small font-weight-bold">{{ lang('Statement From') }}</span>
                                    <input type="date" class="form-control form-control-sm" v-model="filters.date_from">
                                </div>
                                <div class="col-6 px-1">
                                    <span class="small font-weight-bold">{{ lang('To') }}</span>
                                    <input type="date" class="form-control form-control-sm" v-model="filters.date_to">
                                </div>
                            </div>

                            <hr>

                            <div v-for="transaction in transactions"  class="" :key="transaction.reference_no" @click="openTransaction(transaction)">
                                <template v-if="transaction.type == 'INVOICE'">
                                    <div class="font-weight-bold">
                                        {{ dateUi(transaction.datetime, 'YYYY-MM-DD') }}
                                        <span class="float-right">{{ money(Math.abs(transaction.amount)) }} <span class="text-danger font-weight-bold">+</span></span>
                                    </div>
                                    <div class="doc-name">{{ lang('INVOICE') }}</div>
                                    <table width="90%">
                                        <tr v-for="desc in transaction.descriptions" :key="desc.id">
                                            <td>{{ desc.fudc_name }} {{ desc.description_extend }}</td>
                                            <td class="text-right ws-pre">{{ money(desc.total_amount) }}</td>
                                        </tr>
                                    </table>
                                    <div class="font-weight-bold">
                                        <div v-if="transaction.due_date">{{ lang('Payment Due') }}: {{ transaction.due_date }}</div>
                                        {{ transaction.reference_no }}
                                    </div>
                                </template>
                                <template v-else-if="transaction.type == 'PAYMENT'">
                                    <div class="font-weight-bold">
                                        {{ dateUi(transaction.datetime, 'YYYY-MM-DD HH:mm:ss') }} <br>
                                        <span class="float-right">{{ money(Math.abs(transaction.amount)) }} <span class="text-success font-weight-bold">-</span></span>
                                    </div>
                                    <div class="doc-name">{{ lang('RECEIPT') }}</div>
                                    <div class="">
                                        {{ transaction.payment_method }} <span v-if="transaction.customer_phone_no">| {{ transaction.customer_phone_no }}</span>
                                    </div>
                                    <div class="font-weight-bold">
                                        {{ transaction.reference_no }}
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="font-weight-bold">
                                        {{ dateUi(transaction.datetime, 'YYYY-MM-DD') }}
                                        <span class="float-right">
                                            {{ money(Math.abs(transaction.amount)) }}
                                            <span class="font-weight-bold" :class="{'text-danger': transaction.plus_minus == '+', 'text-success': transaction.plus_minus == '-'}">{{ transaction.plus_minus }}</span>
                                        </span>
                                    </div>
                                    <div class="doc-name text-uppercase">{{ lang(transaction.type_ui) }}</div>
                                    <table width="90%">
                                        <tr v-for="desc in transaction.descriptions" :key="desc.id">
                                            <td>{{ desc.fudc_name }} {{ desc.description_extend }}</td>
                                            <td class="text-right ws-pre">{{ money(desc.total_amount) }}</td>
                                        </tr>
                                    </table>
                                    <div class="font-weigth-bold">{{ transaction.reference_no }}</div>
                                </template>
                                <hr>
                            </div>

                            
                            <div v-if="state.loading_next || state.loading_transaction" class="text-center mb-4">
                                Loading..
                            </div>
                            <div v-else-if="!state.loading">
                                <div v-if="initial_balance.initial_balance != 0" class="font-weight-bold">
                                    <span>{{ lang('Balance Brought Forward') }}</span>
                                    <span class="float-right">{{ money(Math.abs(initial_balance.initial_balance)) }}
                                        <span class="text-danger font-weight-bold" v-if="initial_balance.initial_balance > 0">+</span>
                                        <span class="text-success font-weight-bold" v-if="initial_balance.initial_balance < 0">-</span>
                                    </span>
                                </div>
                                <div v-if="initial_balance.opening_amount != 0" class="font-weight-bold">
                                    <span>{{ lang('Opening Amount') }}</span>
                                    <span class="float-right">{{ money(Math.abs(initial_balance.opening_amount)) }}
                                        <span class="text-danger font-weight-bold" v-if="initial_balance.opening_amount > 0">+</span>
                                        <span class="text-success font-weight-bold" v-if="initial_balance.opening_amount < 0">-</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="feedback-form-modal" tabindex="-1" aria-labelledby="feedback-form-modal-label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="feedback-form-modal-label">
                                <span v-if="feedback_inputs.id">{{ lang('Feedback Reply') }}</span>
                                <span v-else>{{ lang('Feedback') }}</span>
                            </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div v-if="feedback_inputs.id">
                                <div class="form-group row">
                                    <label for="reply_content" class="col-12 col-form-label required">{{ lang('Reply') }}</label>
                                    <div class="col-12">
                                        <textarea type="text" class="form-control" name="reply_content" rows="5" v-model="feedback_inputs.reply_content"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div class="form-group row">
                                    <label for="subject" class="col-12 col-form-label required">{{ lang('Subject') }}</label>
                                    <div class="col-12">
                                        <div class="input-group">
                                            <select class="custom-select" name="subject" v-model="feedback_inputs.subject" style="max-width:40%;">
                                                <option selected :value="undefined">{{ lang('-- select --') }}</option>
                                                <option value="COMPLAINT">{{ lang('Complaint') }}</option>
                                                <option value="SUGGESTION">{{ lang('Suggestion') }}</option>
                                            </select>
                                            <input type="text" class="form-control" name="subject_extend" v-model="feedback_inputs.subject_extend">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="content" class="col-12 col-form-label required">{{ lang('Content') }}</label>
                                    <div class="col-12">
                                        <textarea type="text" class="form-control" name="content" rows="5" v-model="feedback_inputs.content"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="content" class="col-12 col-form-label">{{ lang('Attachment') }} <font-awesome-icon icon="plus-circle" size="lg" class="text-danger cursor-pointer" @click="addAttachment"></font-awesome-icon></label>
                                <div class="col-12">
                                    <table width="100%">
                                        <tr v-for="(attachment, i) in feedback_inputs.attachments" :key="i">
                                            <td class="p-1">
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input" :id="'feedback_inputs.attachments.'+ i" @change="onFileChange($event, i)">
                                                    <label class="custom-file-label" :for="'feedback_inputs.attachments.'+ i">{{ lang('Choose file') }}</label>
                                                </div>
                                            </td>
                                            <td class="p-1"><a v-if="attachment.url" href="#" @click.prevent="viewAttachment(attachment.url, attachment.mime)" class="text-primary">{{ lang('view') }}</a></td>
                                            <td class="p-1"><font-awesome-icon icon="trash-alt" class="text-danger cursor-pointer" @click="removeAttachment(i)"></font-awesome-icon></td>
                                        </tr>
                                    </table>
                                    <div v-if="total_attachment_size > 10" class="text-danger">{{ lang("Total image size can't more then 10MB") }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ lang('Back') }}</button>
                            <button v-if="feedback_inputs.id" type="button" class="btn btn-primary" @click="saveFeedbackReply()">{{ lang('Submit') }}</button>
                            <button v-else type="button" class="btn btn-primary" @click="saveFeedback()" :disabled="!can_submit_feedback">{{ lang('Submit') }}</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- View uploaded image -->
            <div class="modal fade" id="view-attachment" tabindex="-1" aria-labelledby="view-attachment-label" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body text-right">
                            <img :src="attachment_url" alt="" width="100%">
                            <button type="button" class="btn btn-primary mt-2" data-dismiss="modal">{{ lang('Close') }}</button>
                        </div>
                    </div>
                </div>
            </div>

            <button v-show="filters.date_from && filters.date_to" :disabled="state.loading_share_pdf" :class="{disabled: state.loading_share_pdf}" class="share-button" @click="shareList">
                <font-awesome-icon icon="share-alt" size="2x"></font-awesome-icon>
            </button>

            <float-bottom>
                <a v-if="page == 'feedback'" href="#" data-toggle="modal" data-target="#feedback-form-modal">
                    <button type="button" class="btn btn-primary btn-block" :disabled="state.loading_feedback">{{ lang('+ Feedback') }}</button>
                </a>
                <a v-else href="#" @click.prevent="payNow()">
                    <button type="button" class="btn btn-primary btn-block" :disabled="state.loading_wallet">{{ lang('Pay Now') }}</button>
                </a>
            </float-bottom>
        </main>
    </div>
</template>

<script>
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'owl.carousel';

export default {
    props: ['bill'],

    data() {
        return {
            transactions: [],
            wallet_details: {},
            initial_balance: {
                initial_balance: 0,
                opening_amount: 0,
            },
            filters: {
                date_from: '',
                date_to: moment().format('YYYY-MM-DD'),
            },
            page: 'home',
            home_banners: [],
            feedbacks: [],
            feedback_inputs: {
                attachments: [{url: ''}],
            },
            attachments_urls: [],
            attachment_url: '',
        }
    },

    watch: {
        'filters.date_from': function(newVal) {
            this.getTransaction()
            this.getInitalBalance()
        },

        'filters.date_to': function(newVal) {
            this.getTransaction()
            this.getInitalBalance()
        },
    },

    computed: {
        is_tenancy: function() {
            return this.bill.service_category.toLowerCase().includes('tenancy')
        },

        total_attachment_size: function() {
            var total_size = 0;
            this.feedback_inputs.attachments.forEach((attachment) => {
                if(attachment.file) {
                    total_size += attachment.file.size
                }
            })
            return total_size/1000000;
        },

        can_submit_feedback: function() {
            return this.total_attachment_size <= 10
        },

        total_unread_reply_count: function() {
            return _.sumBy(this.feedbacks, 'unread_reply_count')
        }
    },

    methods: {
        getHomeBanner() {
            this.$set(this.state, 'loading_home_banner', true)
            axios.post(this.GLOBAL.API_BASE_URL +"home-banner", {
                    branch_id: this.bill.branch_id,
                    sc_id: this.bill.service_category_id
                })
                .then(response => {
                    this.home_banners = response.data.photos
                    this.$nextTick(() => {
                        this.initianteCarousel()
                    })
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading_home_banner', false))
        },

        initianteCarousel() {
            $('.owl-carousel').owlCarousel({
                loop:false,
                margin:10,
                responsive:{
                    0:{
                        items:1
                    },
                }
            });
            $('.owl-carousel').owlCarousel('refresh');
        },

        getInitalBalance() {
            this.$set(this.state, 'loading_initial_balance', true)
            axios.post(this.GLOBAL.API_BASE_URL +"transaction/initial-balance", {
                    identifier: this.bill.identifier,
                    date_from: this.filters.date_from,
                    date_to: this.filters.date_to,
                })
                .then(response => {
                    this.initial_balance = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading_initial_balance', false))
        },

        addAttachment() {
            this.feedback_inputs.attachments.push({url: ''})
            this.$nextTick(() => {
                bsCustomFileInput.init()
            })
        },

        removeAttachment(i) {
            this.feedback_inputs.attachments.splice(i, 1)
        },

        onFileChange(e, i) {
            const file = e.target.files[0];
            var img = new Image();
            img.src = URL.createObjectURL(file);
            this.$set(this.feedback_inputs.attachments[i], 'file', file)
            this.$set(this.feedback_inputs.attachments[i], 'mime', file.type)
            this.$set(this.feedback_inputs.attachments[i], 'url', img.src)
        },

        saveFeedback() {
            this.$set(this.state, 'saving_feedback', true)
            axios.post(this.GLOBAL.API_BASE_URL +"feedback",
                    toFormData({
                        ...{identifier: this.bill.identifier},
                        ...this.feedback_inputs
                    })
                )
                .then(response => {
                    this.getFeedback()
                    $('#feedback-form-modal').modal('hide')
                    this.feedback_inputs = {attachments: [{url: ''}]}
                })
                .catch((error) => {
                    if(error.response.status == 422) {
                        Swal.fire({
                        type: 'error',
                        text: error.response.data.message,
                        timer: 5000,
                    })

                    removeError()

                    $.each(error.response.data.errors, (key, value) => {
                        if(key == 'subject' || key == 'subject_extend') {
                            if(key == 'subject') {
                                $('[name="subject').addClass('is-invalid')
                            }
                            if(key == 'subject_extend') {
                                $('[name="subject_extend').addClass('is-invalid')
                            }
                            $('[name="subject_extend').next('.invalid-feedback').remove()
                            $('[name="subject_extend').after('<div class="invalid-feedback">'+ value[0].replace('subject extend', 'subject') +'</div>')
                        } else {
                            addError(key, value[0])
                        }
                    })

                    $('.form-control.is-invalid:first').focus()
                    } else {
                        defaultErrorManagement(error.response)
                    }

                }) //to catch the errors if any
                .finally(() => this.$set(this.state, 'saving_feedback', false))
        },

        getFeedback() {
            this.$set(this.state, 'loading_feedback', true)
            axios.post(this.GLOBAL.API_BASE_URL +"feedback/list", {
                    identifier: this.bill.identifier,
                    date_from: this.filters.date_from,
                    date_to: this.filters.date_to,
                })
                .then(response => {
                    this.feedbacks = response.data.data
                    this.feedback_next_page = response.data.links.next
                    this.getFeedbackNextPage()
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading_feedback', false))
        },

        getFeedbackNextPage() {
            let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 300 >= document.documentElement.offsetHeight

            if(this.feedback_next_page && bottomOfWindow && this.page == 'feedback') {
                this.$set(this.state, 'loading_next_feedback', true)
                axios.post(this.feedback_next_page, {
                        identifier: this.bill.identifier,
                        date_from: this.filters.date_from,
                        date_to: this.filters.date_to,
                    })
                    .then(response => {
                        this.feedbacks.push.apply(this.feedbacks, response.data.data)
                        this.feedback_next_page = response.data.links.next

                        this.getFeedbackNextPage()
                    })
                    .catch(error=>defaultErrorManagement(error.response))
                    .finally(() => this.$set(this.state, 'loading_next_feedback', false))

                this.feedback_next_page = ''
            }
        },

        replyFeedback(feedback, reply_id) {
            this.feedback_inputs = {
                attachments: [{url: ''}],
                id: feedback.id
            }
            $('#feedback-form-modal').modal('show')
        },

        saveFeedbackReply() {
            this.$set(this.state, 'saving_feedback', true)
            axios.post(this.GLOBAL.API_BASE_URL +"feedback-reply", toFormData(this.feedback_inputs))
                .then(response => {
                    this.getFeedback()
                    $('#feedback-form-modal').modal('hide')
                    
                    this.feedback_inputs = {attachments: [{url: ''}]}
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'saving_feedback', false))
        },

        viewAttachment(url, mime = 'image') {
            if(mime.includes("image")) {
                this.attachment_url = url
                $('#view-attachment').modal('show')
            } else {
                if(window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage("OPEN_URL|url="+ url)
                } else {
                    window.open(url, '_blank', 'noopener')
                }
            }
        },

        getTransaction() {
            this.$set(this.state, 'loading_transaction', true)
            axios.post(this.GLOBAL.API_BASE_URL +"transaction/list", {
                    identifier: this.bill.identifier,
                    date_from: this.filters.date_from,
                    date_to: this.filters.date_to,
                })
                .then(response => {
                    this.transactions = response.data.data
                    this.next_page = response.data.links.next
                    this.current_page = response.data.meta.current_page
                    this.getTransactionNextPage()
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading_transaction', false))
        },

        getTransactionNextPage() {
            let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 300 >= document.documentElement.offsetHeight

            if(this.next_page && bottomOfWindow && this.page == 'transaction') {
                this.$set(this.state, 'loading_next', true)
                axios.post(this.GLOBAL.API_BASE_URL +"transaction/list?page="+ (parseFloat(this.current_page) + 1), {
                        identifier: this.bill.identifier,
                        date_from: this.filters.date_from,
                        date_to: this.filters.date_to,
                    })
                    .then(response => {
                        this.transactions.push.apply(this.transactions, response.data.data)
                        this.next_page = response.data.links.next
                        this.current_page = response.data.meta.current_page

                        this.getTransactionNextPage()
                    })
                    .catch(error=>defaultErrorManagement(error.response))
                    .finally(() => this.$set(this.state, 'loading_next', false))

                this.next_page = ''
            }
        },

        getCustomerWalletDetails() {
            this.$set(this.state, 'loading_wallet', true)
            axios.get(this.GLOBAL.API_BASE_URL +"customer/wallet-details")
                .then((response) => {
                    this.wallet_details = response.data;
                })
                .finally(() => this.$set(this.state, 'loading_wallet', false));
        },

        payNow() {
            if(this.wallet_details.has_pin) {
                this.$router.push({name: 'bills.payment-single', params: {identifier: this.bill.identifier}})
            } else {
                Swal.fire({
                    text: this.lang('Please set your Transaction Pin before payment'),
                    timer: 5000,
                    onClose: () => {
                        this.closeApp('gopay/transaction-pin-setup')
                    }
                })
            }
        },

        openTransaction(transaction) {
            if(transaction.type == 'PAYMENT') {
                this.$router.push({ name: 'receipt', params: {resource_id: transaction.type_id, transaction: transaction, bill: this.bill} })
            }
            else if(transaction.type == 'INVOICE') {
                this.$router.push({ name: 'invoice', params: {resource_id: transaction.type_id, transaction: transaction, bill: this.bill} })
            }
            else if(transaction.type == 'DEBIT_NOTE') {
                this.$router.push({ name: 'debit-note', params: {resource_id: transaction.type_id, transaction: transaction, bill: this.bill} })
            }
            else if(transaction.type == 'CREDIT_NOTE') {
                this.$router.push({ name: 'credit-note', params: {resource_id: transaction.type_id, transaction: transaction, bill: this.bill} })
            }
        },

        shareList() {
            if(this.isNative()) {
                window.ReactNativeWebView.postMessage("SHARE|type=gobills-pdf,resource=transaction,identifier="+ this.bill.identifier +",date_from="+ this.filters.date_from +",date_to="+ this.filters.date_to +",api_token="+ localStorage.token)
            }
            else if('share' in window.navigator) {
                this.$set(this.state, 'loading_share_pdf', true)
                axios.post(this.GLOBAL.API_BASE_URL +"pdf", {
                        resource: 'transaction',
                        identifier: this.bill.identifier,
                        date_from: this.filters.date_from,
                        date_to: this.filters.date_to,
                    },
                    {
                        responseType: 'blob',
                    }
                    )
                    .then((response) => {
                        var file = new File([response.data], this.bill.location +"_"+ this.bill.customer_name +".pdf", {type: "application/pdf"})
                        var files = [file]

                        if(navigator.canShare({ title: this.bill.location +"_"+ this.bill.customer_name, files })) {
                            navigator.share({ title: this.bill.location +"_"+ this.bill.customer_name, files })
                                .catch((err) => console.log(err))
                        }
                    })
                    .catch(error=>{
                        error.response.data.text()
                            .then((data) => {
                                error.response.data = JSON.parse(data)
                                defaultErrorManagement(error.response)
                            })
                    })
                    .finally(() => this.$set(this.state, 'loading_share_pdf', false))
            }
        },

        markAsRead(feedback, reply) {
            axios.post(this.GLOBAL.API_BASE_URL +"feedback/mark-reply-as-read", {
                    reply_id: reply.id,
                })
                .then(response => {
                    if(response.data.updated) {
                        feedback.unread_reply_count -= 1
                        reply.is_unread = false
                    }
                })
                .catch(error=>defaultErrorManagement(error.response))
        },
    },

    beforeRouteEnter (to, from, next) {
        if(to.params.bill == undefined) {
            next({name: 'bills', replace: true})
        }
        else {
            if(['invoice', 'receipt', 'credit-note', 'debit-note'].includes(from.name)) {
                to.params.transaction = true
            }
            next()
        }
    },

    created() {
        this.getHomeBanner()
        this.getCustomerWalletDetails()
        this.getInitalBalance()
        this.getTransaction()
        this.getFeedback()
    },

    mounted() {
        addEventListener("scroll", this.getTransactionNextPage)

        const header_height = $('.header-content').height()
        if(header_height > 0) {
            $('.select-bills .content').css('margin-top', header_height+'px');
        }

        if(this.$route.params.transaction) {
            $('#pills-transaction-tab').click()
        }

        bsCustomFileInput.init()

        $('#feedback-form-modal').on('hidden.bs.modal',  () => {
            this.feedback_inputs = {attachments: [{url: ''}]}
        })
    },

    beforeDestroy() {
        removeEventListener("scroll", this.getTransactionNextPage)
    },
}
</script>

<style lang="scss" scoped>

    .branch-tab {
        .nav {
            border-top: 1px solid #cacaca;
            border-bottom: 1px solid #cacaca;

            box-shadow: inset 0 0 4px #85b6e0;
            background-color: #d3ebff;

            .nav-item:not(:last-child) {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    height: 50%;
                    top: 25%;
                    bottom: 25%;
                    right: -1px;
                    border-right: 2px solid #cacaca;
                }
            }

            .nav-item {
                .active {
                    position: relative;
                    &::before {
                        content: "";
                        position: absolute;
                        width: 90%;
                        left: 5%;
                        right: 5%;
                        bottom: -2px;
                        border-bottom: 4px solid #3490dc;
                    }
                }
            }
        }
    }

    .details {
        display: flex;
        padding-bottom: 0.3rem;
        .logo {
            max-height: 5rem;
            width: 9rem;
            max-width: 25%;
            margin-right: 0.5rem;
            background-repeat: no-repeat !important;
            background-position: center !important;
            background-size: contain !important;
        }

        .location {
            width: 100%;
            max-width: 75%;
        }
    }

    .select-bills {
        .content {
            margin-top: 6.7rem;
        }
    }

    .doc-name {
        font-weight: bold;
    }

    .share-button {
        bottom: 4.5rem;
    }

    .feedback-list {
        .header {
            padding: 0.2rem 1rem;
            background-color: #dae3f3;

            .subject {
                width: 60%;
                margin-right: 1rem;
                > * {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .PENDING {
                color: #2070c0;
            }
            .IN_PROGRESS {
                color: #f82502;
            }
            .CLOSED {
                color: #000000;
            }

            .colapse-icon {
                margin-top: auto;
                margin-bottom: auto;
                .icon {
                    background-color: #335ba3;
                    color: #ffffff;
                    width: 1.8rem;
                    height: 1.8rem;
                    border-radius: 0.9rem;
                    display: flex;
                    transform: rotate(180deg);
                    transition: 0.3s;

                    > * {
                    align-items: center;
                        margin: auto;
                    }
                }
            }

            &.collapsed {
                .colapse-icon {
                    .icon {
                        transform: rotate(0deg);
                    }
                }
            }
        }

        .reply-content {
            white-space: pre-wrap;
            padding: 0.5rem 0.5rem 0.1rem;

            &.ME {
                margin: 0.5rem 0.3rem 0.8rem 3rem;
                background-color: #e2f0d9;
            }
            &.THEM {
                margin: 0.5rem 3rem 0.8rem 0.3rem;
                background-color: #eff7ea;
            }

            .text {
                line-height: 1.2rem
            }
        }

        .badge {
            &.read {
                transition: opacity 5s ease-out;
                opacity: 0;
            }
        }
    }
</style>

<style lang="scss">
.select-bills {
    .owl-carousel {
        padding-top: 2rem;
        padding-bottom: 1rem;
        .owl-dots {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            button:focus {
                outline:0;
            }
        }

        .owl-item {
            img {
                width: 100% !important;
            }
        }
    }
}
</style>